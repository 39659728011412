import React, { useState } from "react";
import { Alert, Skeleton, Checkbox, Icon } from "antd";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Localize } from "components/service";
import { GET_ME } from "graphql/query/user-gql";
import { GET_INSTRUCTION } from "graphql/query/instruction-gql";
import { INSTRUCTIONS_READ } from "graphql/mutation/instruction-gql";
import client from "graphql/client";
import { successNotification, errorNotification } from "components/result";
import Icons from "components/icons";

import "./instruction.css";

const Instruction = () => {
  const { instructionId } = useParams();

  const { me } = client.readQuery({ query: GET_ME });

  const { loading, error, data } = useQuery(GET_INSTRUCTION, {
    variables: { id: instructionId },
  });

  const [isChecked, setIsChecked] = useState(false);

  const [instructionAttachmentsRead] = useMutation(INSTRUCTIONS_READ, {
    update(cache, { data }) {
      const {
        instructionAttachmentsRead: { instructionAttachmentViews },
      } = data;

      const existingUserData = cache.readQuery({
        query: GET_ME,
      });

      const newViews = instructionAttachmentViews.map((view) => ({
        __typename: "InstructionAttachmentView",
        id: view.id,
        relInstructionAttachment: {
          __typename: "InstructionAttachment",
          id: view.relInstructionAttachment.id,
          title: view.relInstructionAttachment.title,
          description: view.relInstructionAttachment.description,
        },
        viewed_at: view.viewed_at,
      }));

      const updatedViews = [
        ...existingUserData.me.relInstructionAttachmentsViews,
        ...newViews.filter(
          (view) =>
            !existingUserData.me.relInstructionAttachmentsViews.some(
              (existingView) => existingView.id === view.id
            )
        ),
      ];

      cache.writeQuery({
        query: GET_ME,
        data: {
          me: {
            ...existingUserData.me,
            relInstructionAttachmentsViews: updatedViews,
          },
        },
      });

      successNotification({
        title: "Success",
        description: "Attachments have been marked as read.",
      });
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);

    if (e.target.checked) {
      const attachmentIds = instruction.relInstructionAttachments.map(
        (attachment) => attachment.id
      );

      instructionAttachmentsRead({
        variables: {
          ids: attachmentIds,
        },
      });
    }
  };

  const handleLinkClick = (id) => {
    instructionAttachmentsRead({
      variables: {
        ids: [id],
      },
    });
  };

  if (loading) {
    return (
      <div className="container">
        <div className="instruction-details">
          <Skeleton active title={{ width: 200 }} paragraph={{ rows: 1 }} />
          <Skeleton active title={{ width: 300 }} paragraph={{ rows: 2 }} />
          <Skeleton active paragraph={{ rows: 1, width: "60%" }} />
          <Skeleton active paragraph={{ rows: 4, width: "100%" }} />
        </div>
      </div>
    );
  }

  if (error) return <Alert message="Error loading data" type="error" />;

  const { instruction } = data;

  const formattedDate = (date) => {
    return new Date(date)
      .toLocaleDateString("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, ".");
  };

  const CompanyRulesTemplate = () => (
    <div className="instruction-details">
      <h1 className="menu-title">{instruction.menu_title}</h1>
      <h2 className="title">{instruction.title}</h2>
      <p className="description">{instruction.description}</p>

      <div className="read-confirm">
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="styled-checkbox"
        />
        <strong>
          <Localize wrap>INSTRUCTION.Text_ReadAllRules</Localize>
        </strong>
      </div>
      <p className="attachments">
        <Icons.AttachFile />{" "}
        <Localize wrap>INSTRUCTION.Text_TotalAttachments</Localize>:{" "}
        {instruction.attachments_total}
      </p>

      {instruction.relInstructionAttachments.length > 0 ? (
        <ol className="attachment-list">
          {instruction.relInstructionAttachments.map((attachment) => {
            const isViewed = me.relInstructionAttachmentsViews.some(
              (viewedAttachment) =>
                viewedAttachment?.relInstructionAttachment?.id === attachment.id
            );

            return (
              <li key={attachment.id} className="attachment-item">
                <div className="title-holder">
                  <h5 className="attachment-title">
                    <a
                      href={attachment.path}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => handleLinkClick(attachment.id)}
                    >
                      <Icons.Document /> {attachment.title}
                    </a>
                  </h5>

                  {isViewed && <Icon type="check-square" />}
                </div>

                <p className="attachment-description">
                  {attachment.description}
                </p>
                <p className="attachment-info">
                  <Localize wrap>INSTRUCTION.Text_FileType</Localize>:{" "}
                  {attachment.extension}
                </p>
                <p className="attachment-date">
                  <Localize wrap>INSTRUCTION.Text_UploadedAt</Localize>:{" "}
                  {formattedDate(attachment.created_at)}
                </p>
              </li>
            );
          })}
        </ol>
      ) : (
        <p>
          <Localize wrap>INSTRUCTION.Text_NoAttachmentsAvailable</Localize>
        </p>
      )}
    </div>
  );

  const CompanyInstructionTemplate = () => (
    <div className="instruction-details">
      <h1 className="menu-title">{instruction.menu_title}</h1>
      <h className="title">{instruction.title}</h>
      <p className="description">{instruction.description}</p>
      <p className="attachments">
        <Icons.AttachFile />{" "}
        <Localize wrap>INSTRUCTION.Text_TotalAttachments</Localize>:{" "}
        {instruction.attachments_total}
      </p>

      {instruction.relInstructionAttachments.length > 0 ? (
        instruction.relInstructionAttachments.map((attachment) => {
          return (
            <div key={attachment.id} className="attachment-item">
              <h5 className="attachment-title">{attachment.title}</h5>
              <p className="attachment-description">{attachment.description}</p>
              <p className="attachment-info">
                <Localize wrap>INSTRUCTION.Text_FileType</Localize>:{" "}
                {attachment.extension}
              </p>
              <p className="attachment-date">
                <Localize wrap>INSTRUCTION.Text_UploadedAt</Localize>:{" "}
                {formattedDate(attachment.created_at)}
              </p>
              <iframe
                src={attachment.path}
                title={attachment.title}
                className="attachment-iframe"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          );
        })
      ) : (
        <p>
          <Localize wrap>INSTRUCTION.Text_NoAttachmentsAvailable</Localize>
        </p>
      )}
    </div>
  );

  return (
    <div className="instruction">
      <div className="container">
        {instruction?.company_rules ? (
          <CompanyRulesTemplate />
        ) : (
          <CompanyInstructionTemplate />
        )}
      </div>
    </div>
  );
};

export default Instruction;
