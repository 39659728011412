import { gql } from "apollo-boost";

export const INSTRUCTIONS_READ = gql`
  mutation InstructionAttachmentsRead($ids: [ID!]!) {
    instructionAttachmentsRead(ids: $ids) {
      label
      message
      instructionAttachmentViews {
        id
        relInstructionAttachment {
          id
          title
          description
        }
        viewed_at
      }
    }
  }
`;
