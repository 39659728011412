import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo";

import { GET_INSTRUCTION_MENU } from "graphql/query/instruction-gql";
import AdditionalMenuPopover from "./additional-menu-popover";
import AdditionalMenuModal from "./additional-menu-modal";
import { AvatarCustom } from "components/avatar";
import { useLogout } from "components/use-hooks";
import { Localize } from "../../service";
import Icons from "components/icons";

import "./additional-menu.css";

const AdditionalMenu = ({ type, user }) => {
  const { logout } = useLogout();
  const Wrap = (props) => {
    return type === "popover" ? (
      <AdditionalMenuPopover {...props} />
    ) : (
      <AdditionalMenuModal {...props} />
    );
  };

  const { data } = useQuery(GET_INSTRUCTION_MENU, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Wrap>
      <AvatarCustom photoUrl={user.avatar} initials={user.initials} size={35}>
        <Link to={`/${user.nickname}`}>
          <Localize>MENUS.Label_MyProfile</Localize>
        </Link>
        {user.diamond_status && (
          <div className="diamond-status">
            <Localize wrap>GLOBAL.Text_DiamondStatus</Localize>{" "}
            <Icons.Diamond />
          </div>
        )}
      </AvatarCustom>
      <nav className={`additional-menu`}>
        <ul>
          <li>
            <Link to="/chat" className="with-icon">
              <Icons.Chat type="menu" />
              <Localize wrap>MENUS.Label_Chat</Localize>
            </Link>
          </li>

          {data?.instructionsMenu?.length
            ? data?.instructionsMenu?.map(({ id, menu_title }) => (
                <li key={+id}>
                  <Link className="with-icon" to={`/instruction/${id}`}>
                    <Icons.Document />
                    <span>{menu_title}</span>
                  </Link>
                </li>
              ))
            : ""}
          <li>
            <button onClick={logout} className="with-icon">
              <Icons.SignOut />
              <Localize wrap>MENUS.Button_Text_Logout</Localize>
            </button>
          </li>
        </ul>
      </nav>
    </Wrap>
  );
};

export default AdditionalMenu;
