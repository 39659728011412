import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

import UserHeaderActions from "./user-header-actions";
import UserHeaderActionsSelf from "./user-header-actions-self";
import AvatarPhoto from "components/avatar/avatar-photo";
import { LinkedMention, Localize } from "components/service";
import { Loader } from "components/result";

import './user-header.css';


const HeaderActions = ({ itsMe, user }) => {

    if(itsMe){
        return <UserHeaderActionsSelf />;
    }

    return (
        <UserHeaderActions
            follow={ user?.me_following }
            userId={ user?.id }
            userNickname={ user?.nickname }
        />
    );
};

const UserHeader = (props) => {

    const {
        loading,
        userLogin,
        itsMe,
        user,
        metaFields = {}
    } = props;

    const {
        contactPhone = {},
        contactEmail = {},
        companyDepartment = {},
        jobDescription = {},
        workingOn = {}
    } = metaFields;

    return (
        <div className="user-header">
            { loading ?
                <Loader /> :
                <Row className={ user?.status === "block" ? "blocked" : "" }>
                    <Col xs={24} sm={24}  md={8} span={8} className="user-header-photo">
                        <AvatarPhoto
                            photoUrl={ user?.avatar }
                            size={150}
                            initials={ user?.initials }
                            blocked={ user?.status === "block" ? "big" : null }
                        />
                    </Col>
                    { user?.status === "block" ?
                        <Col xs={24} sm={24} md={16} span={16} className="user-header-info">
                            <h1>{userLogin}</h1>
                            <h4 className="site-sub-text-color">
                                <Localize>GLOBAL.Text_InactiveUser</Localize>
                            </h4>
                        </Col>:
                        <Col xs={24} sm={24} md={16} span={16} className="user-header-info">
                            <div className="user-header-data">
                                <h1>{userLogin}</h1>
                                <HeaderActions
                                    itsMe={itsMe}
                                    user={user}
                                />
                            </div>
                            <ul className="user-header-statistics company-link-hover">
                                <li className="post-amount-holder site-sub-text-color">
                                    <Link to={`/${userLogin}`}>
                                        <b className="site-bold-color">{ user?.counters.published }</b>
                                        <Localize wrap>MENUS.Label_Posts</Localize>
                                    </Link>
                                </li>
                                <li className="followers-holder site-sub-text-color">
                                    <Link to={`/${user?.nickname}/followers`}>
                                        <b className="site-bold-color">{ user?.counters.followers }</b>
                                        <Localize wrap>MENUS.Label_Followers</Localize>
                                    </Link>
                                </li>
                                <li className="following-holder site-sub-text-color">
                                    <Link to={`/${user?.nickname}/following/users`}>
                                        <b className="site-bold-color">{user?.counters.following}</b>
                                        <Localize wrap>MENUS.Label_Following</Localize>
                                    </Link>
                                </li>
                            </ul>
                            <div className="user-header-about">
                                {user?.name &&
                                    <h2 className="user-header-name">
                                        { user?.name }
                                    </h2>
                                }
                                <div className="user-header-contact">

                                    { !!contactEmail && contactEmail.meta_value !== null &&
                                    <p>
                                        <a href={`mailto:${contactEmail.meta_value}`}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className="outer-link"
                                        >
                                            {contactEmail.meta_value}
                                        </a>
                                    </p>
                                    }
                                    { !!contactPhone && contactPhone.meta_value !== null &&
                                    <p>
                                        <a href={`tel:${contactPhone.meta_value}`}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className="outer-link"
                                        >
                                            {contactPhone.meta_value}
                                        </a>
                                    </p>
                                    }
                                </div>
                                <div className="job-info">
                                    { !!companyDepartment && !!companyDepartment.meta_value &&
                                        <p className="company-department">
                                            <Localize>{ `FORMS.Select_OptionLabel_${ companyDepartment.meta_value }` }</Localize>
                                        </p>
                                    }
                                    { !!jobDescription && !!jobDescription.meta_value &&
                                        <p className="job-description site-sub-text-color">
                                            <Localize>{ `FORMS.Select_OptionLabel_${ jobDescription.meta_value }` }</Localize>
                                        </p>
                                    }
                                    { !!workingOn && !!workingOn.meta_value &&
                                        <p className="working-on">
                                            { workingOn.meta_value }
                                        </p>
                                    }
                                </div>
                                { user?.about ?
                                    <div className="user-header-description">
                                        <LinkedMention searchSigns={['@', '^', '#']}>
                                            {user?.about}
                                        </LinkedMention>
                                    </div> : null
                                }
                            </div>
                        </Col>
                    }
                </Row>
            }
        </div>
    );
};

export default UserHeader;