import { gql } from "apollo-boost";

export const GET_INSTRUCTION_MENU = gql`
  query GetInstructionsMenu {
    instructionsMenu {
      id
      title
      menu_title
      description
      author_id
      menu_show
      menu_order
      views
      created_at
      updated_at
    }
  }
`;

export const GET_INSTRUCTION = gql`
  query GetInstruction($id: ID) {
    instruction(id: $id) {
      id
      title
      menu_title
      description
      author_id
      menu_show
      menu_order
      views
      attachments_total
      company_rules
      relInstructionAttachments {
        id
        instruction_id
        author_id
        path
        title
        description
        extension
        server_name
        size
        order
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;
